.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 5px;
 
  }
  
  .pagination li a {
    color: #000;
    text-decoration: none;
    /* background-color: #dae0de; */
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .475rem;
    border: ridge 0.5px;
    width: 40px;
    height: 40px;
    
  }
  
  .pagination li.active a {
    background-color: #45dbaf;
    color: #fff;
    width: 40px;
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .475rem;
}
  
  .pagination li.disabled a {
    pointer-events: none;
    cursor: not-allowed;
  }
  
  .pagination .previous,
  .pagination .next {
    margin: 0 10px;

    -webkit-user-select: none; /* Safari and older versions of Safari on iOS */
    user-select: none; /* Standard syntax */
  }
  